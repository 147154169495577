import dayjs from 'dayjs'
import { Dispatch, ReactNode, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRight } from '../ArrowRight.tsx'
import { showGeneratedSummary, UsedLinks } from '../helpers.ts'
import AudioPlayerWrapper from './AudioPlayerWrapper.tsx'
import MyMarkdown from './Markdown.tsx'
import { ResultFunction } from './ResultFunction.tsx'
import { ResultSearchZueri } from './ResultSearchZueri.tsx'
import { ResultSearchZueriEntry, SearchResult } from './ResultSearchZueriEntry.tsx'

import('dayjs/locale/de')

export const Result = (props: {
  queryResult: string
  query: string
  erzFunction: { query: string; function: string } | null
  linksResult: string
  changeModel: () => void
  isLoading: boolean
  maybeBadRating: boolean
  quickFeedback: ReactNode
  otherModelButton: boolean
  userId: string | null
  setLinksResult: Dispatch<SetStateAction<string>>
  usedLinksResult: UsedLinks[]
}) => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)

  const DO_AUDIO_BUTTON = !!queryParams.get('audio')

  const searchResults: SearchResult[] = useMemo(() => {
    return props.usedLinksResult?.map(link => {
      const breadcrumbsText = link.title?.split('>') || []
      const title = breadcrumbsText.pop() || ''
      const breadcrumbs = breadcrumbsText
        .map(breadcrumb => breadcrumb.trim())
        .map(breadcrumb => ({ text: breadcrumb, link: '' }))

      return {
        title,
        image: link.smallImage,
        description: link.description || '',
        date: dayjs(link.date).format('dddd, D. MMMM YYYY') || '',
        breadcrumbs: breadcrumbs,
        currentSection: link.considered
          ? 'Seiten:'
          : 'Für die Antwort nicht berücksichtigte Seiten (aber evt. doch relevant):',
        link: link.url,
        summary: link.summary || '',
        considered: link.considered,

        type: link.type || null,
      }
    })
  }, [props.usedLinksResult, t])

  if (props.queryResult === '') {
    return null
  }
  dayjs.locale('de') // use loaded locale globally

  const hasFunctionResult = props.erzFunction?.function && props.erzFunction.function.length > 0
  const searchResultsConsidered = searchResults.filter(sr => sr.considered)
  const searchResultsNotConsidered = searchResults.filter(sr => !sr.considered)

  return (
    <>
      <div className="resultBoxContainer">
        {hasFunctionResult && <ResultFunction functionQuery={props.erzFunction} userId={props.userId} />}
        <ResultSearchZueri query={props.query} userId={props.userId} />

        <div className="resultBox" translate="no">
          {props.queryResult !== t('results.initial') && (
            <>
              <h1>Suchresultate von ZüriCityGPT</h1>
            </>
          )}

          {searchResultsConsidered.length > 0 && props.linksResult !== '__NO_LINKS__' && (
            <div className="zueriSearchResult" translate="no">
              <ResultSearchZueriEntry entries={searchResultsConsidered} urlPrefix={''} />
            </div>
          )}
          {props.queryResult !== t('results.initial') && searchResults.length > 0 && (
            <>
              <hr style={{ clear: 'both' }} />
              <h1>Chatbot Antwort:</h1>
            </>
          )}

          <div style={{ minHeight: '20em' }}>
            <MyMarkdown
              markdown={
                props.queryResult +
                (props.isLoading
                  ? ' ![](https://chregus.rokka.io/dynamic/crop-width-200-height-25--resize-width-20/o-af-1/b9a927/circles-menu-1.gif)'
                  : '')
              }
              usedLinks={props.usedLinksResult}
            ></MyMarkdown>
          </div>

          {DO_AUDIO_BUTTON && !props.isLoading && props.queryResult && <AudioPlayerWrapper text={props.queryResult} />}
          {searchResultsNotConsidered.length > 0 && props.linksResult !== '__NO_LINKS__' && (
            <div className="zueriSearchResult" translate="no">
              <ResultSearchZueriEntry entries={searchResultsNotConsidered} urlPrefix={''} />
            </div>
          )}
          <div className="zueriSearchResult" translate="no">
            {searchResults.some(result => showGeneratedSummary(result)) && (
              <>
                <hr />
                <p className={'description'}>* AI generated summary</p>
              </>
            )}
          </div>
        </div>
      </div>

      {props.otherModelButton && props.maybeBadRating && (
        <>
          {' '}
          <hr />
          <div className="maybeBadRating">
            <span style={{ marginRight: '0.5em' }}>{t('answers.unhappy')}</span>
            <button type="submit" onClick={props.changeModel} className="button button--model">
              <span>{t('answers.otherModelButton')}</span>
              <ArrowRight />
            </button>
          </div>
        </>
      )}

      {props.linksResult && (
        <>
          <hr />
          {props.quickFeedback}
        </>
      )}
      {props.otherModelButton && !props.maybeBadRating && (
        <>
          <hr />

          <button type="submit" onClick={props.changeModel} className="button button--model">
            <span>{t('answers.otherModelButton')}</span>
            <ArrowRight />
          </button>
        </>
      )}
    </>
  )
}
